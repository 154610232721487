import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Table, Skeleton, Empty } from "antd";
import { AppContext } from "../contexts/AppContext";
import {getBaseUrl} from "./ApiConstants";

const ControlLogs = () => {
    const { showNotification } = useContext(AppContext);

    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState([]);


    const getLogs = async () => {
        const config = {
            method: 'get',
            url: getBaseUrl() + '/user-logs',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('token'),
            }
        };
        try {
            const response = await axios(config);
            const sortedLogs = response.data.sort((a, b) => b.timestamp - a.timestamp);

            setLogs(sortedLogs); 
            setLoading(false);
        } catch (error) {
            console.error('Error fetching logs:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getLogs();
    }, []);

    const columns = [
        {
            title: 'Användarnamn',
            dataIndex: 'userName',
            width: '10%',
        },
        {
            title: 'Kund-nr',
            dataIndex: 'customerNumber',
            width: '4%',
        },
        {
            title: 'Kontaktinfo',
            dataIndex: 'contactInfo',
            width: '7%',
        },
        {
            title: 'Organisationsnummer',
            dataIndex: 'corporateId',
            width: '5%',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            width: '8%',
        },
        {
            title: 'Telefon',
            dataIndex: 'phoneNumber',
            width: '8%',
        },
        {
            title: 'Tidpunkt',
            dataIndex: 'timestamp',
            width: '10%',
            render: (timestamp) => {
                const date = new Date(timestamp < 1000000000000 ? timestamp * 1000 : timestamp);
                return date.toLocaleString();
            },
        },
        {
            title: 'Provfack',
            dataIndex: 'sampleArea',
            width: '6%',
        },
    ];

    return (
        <div>
            <div style={{ padding: '10px', fontSize: '20px' }}>
                Här loggas de användare som tryckt "NEJ" på kontrollsida.
            </div>
            <Table
                dataSource={loading ? [] : logs}
                columns={columns}
                scroll={{ x: 1300 }}
                rowKey={record => record.id}
                locale={{
                    emptyText: loading ? <Skeleton active /> : <Empty />,
                }}
                pagination={{
                    defaultPageSize: 30,
                }}
            />
        </div>
    );
};

export default ControlLogs;