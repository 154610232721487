import {
    Button,
    Card,
    DatePicker,
    Input,
    Divider,
    AutoComplete,
    Select,
    Form,
    Table,
    Skeleton,
    Empty,
    Tag,
    Modal,
    Checkbox,
    Popconfirm,
    Switch, Typography, notification,
    Spin,
    Image
} from "antd";

import {
    UserOutlined,
    PlusOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
    PictureOutlined,
} from '@ant-design/icons';
import React, {useContext, useEffect, useState} from "react";
import {getBaseUrl} from "./ApiConstants"
import moment from 'moment/moment';
import locale from 'antd/es/date-picker/locale/sv_SE';
import axios from "axios";
import { AppContext } from "../contexts/AppContext";
import Receipt from "./Receipt"

const { confirm } = Modal;
const loadIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

const saveButton = (url, photoName) => {
     const downloadLink = document.createElement('a');
     downloadLink.href = url;
     downloadLink.download = photoName;
     document.body.appendChild(downloadLink);
     downloadLink.click();
     document.body.removeChild(downloadLink);
  };

const SnapshotModal = ({ imageData, visible, loading, onCancel, photoToShow}) => {
  return (
    <Modal width={1000} visible={visible} onCancel={onCancel}
          footer={[
            <Button key="save" type="primary" onClick={() => saveButton(imageData, photoToShow)}>
                  Spara
              </Button>,
              <Button key="submit" type="primary" onClick={onCancel}>
                  Stäng
              </Button>
          ]}>

        {loading ? (
          <Spin indicator={loadIcon} />
        ) : (
          <Image style={{width: '100%', height: '100%'}} src={imageData} />
        )}
    </Modal>
  );
};

const MaterialManagement = () => {

    const { showNotification } = useContext(AppContext)

    const today = moment()
    const [dateInterval, setDateInterval] = useState([today, today])
    const [materialArray, setMaterialArray] = useState([])
    const [username, setUsername] = useState()
    const [userId, setUserId] = useState()
    const [material, setMaterial] = useState('')
    const [litteraArray, setLitteraArray] = useState([])
    const [litteraArray2, setLitteraArray2] = useState([])
    const [littera, setLittera] = useState('')
    const [dumpSite, setDumpSite] = useState('')

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState();
    const [cargoData, setCargoData] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false)
    const [formAddNew] = Form.useForm();
    const [loadingUpdateSorted, setLoadingUpdateSorted] = useState(false)
    const [snapshotModalVisible, setSnapshotModalVisible] = useState(false);
    const [snapshotLoading, setSnapshotLoading] = useState(false)
    const [snapshotData, setSnapshotData] = useState('')
    const [currentPhoto, setCurrentPhoto] = useState('')
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showReceipt, setShowReceipt] = useState(false); 

    useEffect(() => {
        getCargo()
    }, []);

    useEffect(() => {
        let sortedData = []

        // Sort by time as default
        if (cargoData?.length) {
            sortedData = cargoData.sort((x, y) => {
                return y.timestamp - x.timestamp
            })
        }
        setFilteredList(sortedData)
    }, [cargoData])

    const getTotalWeight = () => {
        let total = 0
        if(filteredList && filteredList.length) {
            filteredList.forEach(i => {
                if(i.weight) {
                    total += i.weight
                }
            })
        }
        return total
    }

    function onChangeInterval(date, dateArray) {
        setDateInterval(dateArray.map(d => moment(d)))
    }

    const fetchAllUsers = async () => {
        await fetch(getBaseUrl() + '/user/', {
            headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                }
            )
        }).then(r => r.json())
            .then(r => setUsers(r))
    }

    const updateMaterialState = (r) => {
        setMaterialArray(r.sort())
    }

    const onClickSnapshotBtn = (cargo) => {
        setCurrentPhoto(cargo.photoFileName); 
        setSnapshotModalVisible(true);
        setSnapshotLoading(true);

        authSnapshot(cargo.photoFileName)
    };


    const fetchSnapshot = async (filename, token) => {
        const imageUrl = `https://materialliggare.kitscontractor.se/api2/Rtsp/GetFile?fileName=${filename}`
        const res = await fetch(imageUrl, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setSnapshotLoading(false)
        setSnapshotData(imageObjectURL);
    }

    const authSnapshot = (filename) => {

        const username = 'HexaKits'
        const password = 'Lingonsylt'

        // Auth
        axios({
            method: 'post',
            url: `https://materialliggare.kitscontractor.se/api2/Auth/login?username=${username}&password=${password}`,
        })
            .then(function (res) {
               // console.log(res)
                if (res?.data?.token) {
                    fetchSnapshot(filename, res?.data?.token)
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    useEffect(() => {
        fetchAllUsers()
        fetchAllMaterials()
    }, []);


    const fetchAllMaterials = () => {
        fetch(getBaseUrl() + '/material/getallmaterials', {
            headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                }
            )
        }).then(r => (r.json())
            .then(r => updateMaterialState(r)))
    }

    const handleUsernameChange = (v) => {
        setUsername(v)
        for (const element of users) {
            if (element.username === v) {
                setUserId(element.id)
            }
        }
    }

    const handleSelect = (username) => {
        fetch(getBaseUrl() + '/cargo/littera/', {
            headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                }
            )
        }).then(r => (r.json()).then(
            r => setLitteraArray(r)))
    }

    useEffect(() => {
        handleSelect(username)
    }, [username]);


    useEffect(() => {
        addKeyToUsernames()
    }, [users]);

    useEffect(() => {
        addKeyToMaterial()
    }, [materialArray]);

    useEffect(() => {
        addKeyToLittera()
    }, [litteraArray]);


    const [usernameArray, setUsernameArray] = useState([])
    const addKeyToUsernames = () => {
        let tempArr = [];
        for (let i = 0; i < users?.length; i++) {
            tempArr.push(
                {
                    value: (users[i].username)
                }
            )
        }

        setUsernameArray(tempArr)
    }

    const updateSorted = async (record, checked) => {
        const newData = [...cargoData];
        const index = newData.findIndex((item) => record.id === item.id);

        if (index > -1) {
            var config = {
                method: 'patch',
                url: getBaseUrl() + '/cargo/' + record.id,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                },
                data: {
                    sortedOut: checked
                }
            };
            setLoadingUpdateSorted(true)
            axios(config)
                .then(function (response) {
                    setLoadingUpdateSorted(false)
                    //console.log(response)
                    const item = newData[index];
                    newData.splice(index, 1, { ...item, sortedOut: checked });
                    ////console.log(newData, item, row)
                    setCargoData(newData);
                    setEditingKey('');
                })
                .catch(function (error) {
                    setLoadingUpdateSorted(false)
                    console.log(error);
                    showNotification('Något gick fel', '', 'error')
                });


        } else {
            setEditingKey('');
        }
    }

    const onUpdateSorted = async (record, checked) => {

        if(checked) {
            confirm({
                icon: <InfoCircleOutlined />,
                content: <h3>Bekräfta sortering</h3>,
                onOk() {
                    updateSorted(record, checked)
                },
                onCancel() {
                },
                okText: 'Bekräfta',
                cancelText: 'Avbryt',
                closable: true
            })
        } else {
            updateSorted(record, checked)
        }
    }

    const [materialArray2, setMaterialArray2] = useState([])
    const addKeyToMaterial = () => {
        let tempArr = [];
        for (let i = 0; i < materialArray.length; i++) {
            tempArr.push(
                {
                    value: materialArray[i]
                }
            )
        }
        setMaterialArray2(tempArr)
    }

    const addKeyToLittera = () => {
        let tempArr = [];
        for (let i = 0; i < litteraArray.length; i++) {
            tempArr.push(
                {
                    value: litteraArray[i]
                }
            )
        }
        setLitteraArray2(tempArr)
    }

    function getCargo() {
        setShowTable(true)
        const baseUrl = getBaseUrl() + "/cargo"
        let urlParams = new URLSearchParams()
        setLoading(true)

        ////////console.log(baseUrl + "?" + urlParams)

        if (userId !== 0 && userId !== undefined) {
            urlParams.set('userId', userId)
        }

        if (material !== '') {
            urlParams.set('material', material)
        }
        if (littera !== '') {
            urlParams.set('littera', littera)
        }

        if (dumpSite !== '') {
          urlParams.set('dumpSite', dumpSite)
      }

        if (dateInterval[0] !== '' && dateInterval[0] !== undefined) {
            const from = dateInterval[0].format('YYYY-MM-DD')
            const to = dateInterval[1].format('YYYY-MM-DD')
            urlParams.set('date1', from);
            urlParams.set('date2', to);
        }

        fetch(baseUrl + "?" + urlParams, {
            headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                }
            )
        }).then(r => r.json())
            .then(r => {
                setLoading(false)
                setCargoData(r)
            })
    }

    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.id === editingKey;

    const cancel = () => {
        setEditingKey('');
    };

    const columns = [
        {
            title: '',
            dataIndex: 'operation',
            width: '4%',
            fixed: 'left',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{}}
                        >
                            Spara
                        </Typography.Link>
                        <br />
                        <a onClick={cancel}>Avbryt</a>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Ändra
                    </Typography.Link>
                );
            },
        },
        {
            title: 'Användare',
            dataIndex: 'username',
            width: '10%',
            //fixed: 'left',
            editable: true,
            sorter: (a, b) => a.username.localeCompare(b.username),
            //onchange: (value) => handleUsernameChange(value)
        },
        {
            title: 'Material',
            dataIndex: 'material',
            width: '12%',
            editable: true,
            sorter: (a, b) => a.material.localeCompare(b.material)
        },
        {
            title: 'Tidpunkt',
            dataIndex: 'time',
            width: '4%',
            editable: false,
            sorter: (a, b) => a.time.localeCompare(b.time)
        },
        {
            title: 'Datum',
            dataIndex: 'date',
            width: '6%',
            editable: false,
            sorter: (a, b) => a.date.localeCompare(b.date)
        },
        {
            title: 'Sortering',
            dataIndex: 'sortedOut',
            width: '5%',
            editable: false,
            render: (value, record) => {
                return loadingUpdateSorted ? <Spin indicator={loadIcon} /> : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                        onChange={(e) => onUpdateSorted(record, record.sortedOut ? false : true)}
                        checked={record.sortedOut === true}
                            style={{marginLeft: 6}}
                        />
                        {record.photoFileName ? (<Button
                            icon={<PictureOutlined />}
                            onClick={() => onClickSnapshotBtn(record)}
                            style={{ marginLeft: 10 }}
                        />) : null}
                    </div>
                )
            }
        },
        {
            title: 'Vikt',
            dataIndex: 'weight',
            width: '6%',
            editable: true,
            sorter: (a, b) => a.weight - b.weight,
            render: (text, record) => {
                return text + " ton"
            }
        },
        {
            title: 'Littra',
            dataIndex: 'littera',
            width: '6%',
            editable: true,
            sorter: (a, b) => a.littera.localeCompare(b.littera)
        },
        {
            title: 'Fordon',
            dataIndex: 'vehicle',
            width: '8%',
            editable: false,
            sorter: (a, b) => a.vehicle.localeCompare(b.vehicle)
        },
        {
            title: 'Adress',
            dataIndex: 'origin',
            width: '8%',
            editable: false,
            sorter: (a, b) => a.origin.localeCompare(b.origin)
        },
        {
            title: 'ID',
            dataIndex: 'id',
            width: '5%',
            editable: false,
            sorter: (a, b) => a.id - b.id
        },
        
        {
            title: 'Tipplats',
            dataIndex: 'dumpSite',
            minWidth: '10%',
            editable: true,
            sorter: (a, b) => a.dumpSite.localeCompare(b.dumpSite)
        },
        {
            title: 'Kvitto',
            minWidth: '3%',
            editable: false,
            render: (text, record) => (
                <Button
                    type="primary"
                    onClick={() => handleReceiptButtonClick(record)}
                >
                    Kvitto
                </Button>
            )
        },
        {
            title: 'Provfack',
            dataIndex: 'sampleArea',
            minWidth: '15%',
            editable: true,
            sorter: (a, b) => a.sampleArea.localeCompare(b.sampleArea)
        },
    ];

    const handleReceiptButtonClick = (data) => {
        setSelectedOrder(data); 
        setShowReceipt(true); 
    }

    const handleCloseReceipt = () => {
        setShowReceipt(false);
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const EditableCell = ({

                              editing,
                              dataIndex,
                              title,
                              inputType,
                              record,
                              index,
                              children,
                              ...restProps
                          }) => {

        const inputNode =   (() => {
            switch(dataIndex) {

              case 'dumpSite':
                    return   <Select
                        showArrow={false}
                        allowClear
                        style={{width: 200}}
                        showSearch
                        className="autoComplete"
                        options={[{value: 'Golfbanan'}, {value: 'Ulvgatan'}, {value: 'Aros Park'}]}
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }

                    >
                        <Input style={{height: '50px'}}/>
                    </Select>;

                case 'material':
                    return   <Select
                        showArrow={false}
                        allowClear
                        style={{width: 200}}
                        showSearch
                        className="autoComplete"
                        options={materialArray2}
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }

                    >
                        <Input style={{height: '50px'}}/>
                    </Select>;
                case 'username':
                    return        <Select
                        showArrow={false}
                        allowClear
                        style={{ width: 200 }}
                        showSearch
                        className="autoComplete"
                        options={usernameArray}
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }

                    >
                        <Input style={{height: '50px'}}/>
                    </Select>;
                default:
                    return <Input/>;
            }
        })()

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: false,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    function onSearch(value) {
        value = value.target.value
        const newList = cargoData?.filter(item => JSON.stringify(item).toLowerCase().includes(value.toLowerCase()))
        setFilteredList(newList)
    }


    const edit = (record) => {
        form.setFieldsValue({
            username: '',
            corporateId: '',
            invoiceCity: '',
            email: '',
            reportToUser: '',


            ...record,
        });
        setEditingKey(record.id);
    };

    function cancelAddNew() {
        setShowAddNew(false)
        formAddNew.resetFields();
    }

    const save = async (key) => {
        try {

            let uuid;
            let username;

            const row = await form.validateFields();

            for (const element of users) {
                if (element.username === row.username) {
                    uuid = element.id
                    username = element.username
                }
            }

            if (uuid) {
                row.kitsUserId = uuid
                row.username = username
            }

            console.log(row);
            const newData = [...cargoData];
            console.log(newData);
            const index = newData.findIndex((item) => key === item.id);
            console.log(index);

            if (index > -1) {
                var config = {
                    method: 'patch',
                    url: getBaseUrl() + '/cargo/' + key,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem(('token'))
                    },
                    data: row
                };
                axios(config)
                    .then(function (response) {
                        const item = newData[index];
                        newData.splice(index, 1, {...item, ...row});
                        ////console.log(newData, item, row)
                        setCargoData(newData);
                        setEditingKey('');
                        console.log(response);
                    })
                    .catch(function (error) {
                        ////console.log(error);
                        showNotification('Något gick fel', '', 'error')
                        console.log(error);
                    });


            } else {
                newData.push(row);
                setCargoData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            ////console.log('Validate Failed:', errInfo);
        }
    };

    const deleteCargo = (id) => {
        if(!id) { return }
        const config = {
            method: 'delete',
            url: getBaseUrl() + '/cargo/'+ id,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem(('token'))
            },
        };

        axios(config)
            .then(function (res) {
                console.log(res)
            })
            .catch(function (error) {
                showNotification('Något gick fel', '', 'error')
                console.log('error', error.response);
            });
    }

    const onSubmitNewCargo = (values) => {
        //console.log(values)

        let uId = null
        for (const u of users) {
            if (u.username === values.username) {
                uId = u.id
            }
        }

        const data = {
            vehicle: values.vehicle?.toUpperCase(),
            kitsUserId: uId,
            origin: values.origin,
            material: values.material,
            littera: values.littera,
            weight: values.weight,
            dumpSite: values.dumpSite
        }

        const config = {
            method: 'post',
            url: getBaseUrl() + '/cargo',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem(('token'))
            },
            data: data,
        };

        axios(config)
            .then(function (res) {
                if(res?.data) {
                    getCargo()
                    cancelAddNew()
                }
            })
            .catch(function (error) {
                showNotification('Något gick fel', '', 'error')
                console.log('error', error.response);
            });
    }

    if(showAddNew) {
        return (
            <div>

                <h2 className="h2">
                    Lägg till last
                </h2>

                <Form
                    form={formAddNew}
                    name="basic"
                    layout='vertical'
                    initialValues={{ remember: true }}
                    onFinish={onSubmitNewCargo}
                    autoComplete="off"
                >
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>

                        <Form.Item
                            label="Material"
                            name="material"
                            style={{ width: '45%' }}
                            rules={[{ required: true, message: 'Ange material' }]}
                        >
                            <Select
                                showArrow={false}
                                allowClear
                                showSearch
                                className="autoComplete"
                                options={materialArray2}
                                filterOption={(inputValue, option) =>
                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }

                            >
                                <Input style={{height: '50px'}}/>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Vikt"
                            name="weight"
                            style={{ width: '45%' }}
                            rules={[{ required: true, message: 'Ange vikt' }]}
                        >
                            <Input
                                addonAfter="ton"
                                type="number"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Användare"
                            name="username"
                            style={{ width: '45%' }}
                            rules={[{ required: true, message: 'Ange användare' }]}
                        >
                            <Select
                                showArrow={false}
                                allowClear
                                showSearch
                                className="autoComplete"
                                options={usernameArray}
                                filterOption={(inputValue, option) =>
                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            >

                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Fordon / regnummer"
                            name="vehicle"
                            style={{ width: '45%' }}
                            rules={[{ required: true, message: 'Ange fordon' }]}
                        >
                            <Input
                                style={{textTransform: 'uppercase'}}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Littra"
                            name="littera"
                            style={{ width: '45%' }}
                            rules={[{ required: true, message: 'Ange littra' }]}
                        >
                            <Input
                            />
                        </Form.Item>

                        <Form.Item
                            label="Adress"
                            name="origin"
                            style={{ width: '45%' }}
                            rules={[{ required: true, message: 'Ange adress' }]}
                        >
                            <Input
                            />
                        </Form.Item>

                        <Form.Item
                            label="Tipplats"
                            name="dumpSite"
                            style={{ width: '45%' }}
                            rules={[{ required: true, message: 'Ange tipplats' }]}
                        >
                            <Select
                                showArrow={false}
                                allowClear
                                showSearch
                                className="autoComplete"
                                options={[{value: 'Golfbanan'}, {value: 'Ulvgatan'}, {value: 'Aros Park'}]}
                                filterOption={(inputValue, option) =>
                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />} 
                                    style={{height: '50px'}}/>
                            </Select>
                        </Form.Item>

                        <div style={{width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <Form.Item
                                style={{ width: '50%' }}
                            >
                                <Button style={{ width: '100%' }}
                                    type="ghost" onClick={cancelAddNew}>
                                    Avbryt och gå tillbaka
                                </Button>
                            </Form.Item>

                            <Form.Item
                                style={{ width: '50%' }}
                            >
                                <Button className='blackButton' type="primary" htmlType="submit">
                                    Lägg till ny last
                                </Button>
                            </Form.Item>
                        </div>

                    </div>
                </Form>
            </div>
        )
    }

    return (
        <Card style={{ position: 'relative' }}>
            {showReceipt && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)', 
                        zIndex: 10, 
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                        <Receipt orderData={selectedOrder} onClose={handleCloseReceipt} />
                </div>
            )}
            <h2>Filter</h2>
            <Card bodyStyle={{display: "flex", flexDirection: "row", gap: "20px"}}>
            <Select
                showArrow={false}
                allowClear
                showSearch
                className="autoComplete"
                options={usernameArray}
                placeholder="Filtrera på användarnamn"
                filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(v) => handleUsernameChange(v)}
                onClear={() => setUserId(undefined)}
            >
            </Select>

            <Select
                showArrow={false}
                allowClear
                showSearch
                className="autoComplete"
                options={materialArray2}
                placeholder="Filtrera på material"
                filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(v) => setMaterial(v)}
                onClear={() => setMaterial('')}
            >
                <Input style={{height: '50px'}}/>
            </Select>

            <Select
                showArrow={false}
                allowClear
                showSearch
                className="autoComplete"
                options={litteraArray2}
                placeholder="Filtrera på littra"
                filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(v) => setLittera(v)}
                onClear={() => setLittera('')}
            >
                <Input style={{height: '50px'}}/>
            </Select>

            <Select
                showArrow={false}
                allowClear
                showSearch
                className="autoComplete"
                options={[{value: 'Golfbanan'}, {value: 'Ulvgatan'}, {value: 'Aros Park'}]}
                placeholder="Filtrera på Tipplats"
                filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(v) => setDumpSite(v)}
                onClear={() => setDumpSite('')}
            >
                <Input style={{height: '50px'}}/>
            </Select>

            <DatePicker.RangePicker className="datePicker" placeholder={["Startdatum", "Slutdatum"]}
                                    onChange={onChangeInterval}
                                    locale={locale}
                                    value={dateInterval}
            />

            <form onSubmit={getCargo}>

              <Button block className='blackButton' onClick={getCargo} type="primary"
                    value="Ladda ned rapport">Sök lastrapporter</Button>
            </form>
            </Card>

            <h2 className="h2 reportHeaderPadding">Lastrapporter</h2>
            


            {
                !showTable ? <></> :
                    <Form form={form} component={false}>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 20,
                            margin: 10,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Input
                                placeholder='Sök efter ...'
                                allowClear
                                onChange={onSearch}
                            /> eller
                            <Button
                                onClick={() => setShowAddNew(true)}
                                type={'primary'}
                                icon={<PlusOutlined />}
                            >
                                Lägg till last
                            </Button>

                        </div>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            dataSource={loading ? [] : filteredList}
                            columns={mergedColumns}
                            scroll={{x: 'max-content'}}
                            rowClassName="editable-row"
                            rowKey={record => record.id}
                            locale={{
                                emptyText: loading ? <Skeleton active={true}/> : <Empty/>
                            }}
                            pagination={false /*{
                                onChange: cancel,
                                defaultPageSize: 1000,

                            }*/}
                            summary={() => (
                                <Table.Summary fixed={'top'}>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={2}>
                                            <span style={{fontWeight: 'bold'}}>Totalt:   {getTotalWeight()} ton</span>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            )}
                        />
                    </Form>
            }
            

            <SnapshotModal
                imageData={snapshotData}
                visible={snapshotModalVisible}
                onCancel={() => setSnapshotModalVisible(false)}
                loading={snapshotLoading}
                photoToShow={currentPhoto}
            />

        </Card>
    )
}


export default MaterialManagement
